import styled from 'styled-components'
import Carousel from 'react-multi-carousel'

export const CarouselStyles = styled(Carousel)`
  padding: 80px 0px;
  height: 100% !important;
  
  .react-multi-carousel-track {
    height: 50rem;
  }

  .react-multi-carousel-dot-list {
    bottom: 3rem;
    display: grid;
    grid-auto-flow: column;
  }

  .dot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #147efb;
    color: #147efb;
    display: grid;
    place-items: center;
    margin: 0 1rem;
    cursor: pointer;
    background: white;
    
    &.space {
      border: 0;
      cursor: initial;
    }

    &.active {
      background: #147efb;
      color: white;
    }
`

const ItemNew = styled.div`
  border-radius: 50%;
  width: 300px;
  height: 300px;
  background-color: #0c4779;
  opacity: 0.8;
  :hover {
    background-color: #3781bf;
    opacity: 0.75;
  }
  .contentTestimonial {
    text-align: center;
    height: 100%;
    padding: 20% 14%;
    color: #ffffff;
  }

  .contentTestimonial-content {
    display: flex;
    align-items: center;
    height: 75%;
  }

  .contentTestimonial-content__descrition {
    margin: 0;
    weight: 100%;
    text-align: start;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.2em;
  }

  .contentTestimonials-info {
    display: inline-block;
    margin-top: 10px;
    weight: inherit;
    font-size: 1rem;
    font-weight: 500;
  }
  .contentTestimonials-info__author {
    text-align: start;
    margin: 0;
  }
  .contentTestimonials-info__carrier {
    text-align: start;
    margin: 0;
  }

  @media (min-width: 769px) {
    width: 320px;
    height: 320px;

    .contentTestimonial-content__descrition {
      font-size: 1.6rem;
    }

    .contentTestimonials-info {
      font-size: 1.2rem;
    }
  }

  @media (min-width: 1240px) {
    width: 400px;
    height: 400px;

    .contentTestimonial-content__descrition {
      font-size: 1.8em;
    }

    .contentTestimonials-info {
      font-size: 1.4rem;
    }
  }

  @media (min-width: 1540px) {
    width: 500px;
    height: 500px;

    .contentTestimonial-content__descrition {
      font-size: 2rem;
    }

    .contentTestimonials-info {
      font-size: 1.6rem;
    }
  }
`

export { ItemNew }
