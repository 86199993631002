import React, { useMemo } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

import SecondSectionWrapper from './secondSection.style'

export const SecondSection = () => {
  const Data = useStaticQuery(graphql`
    {
      allStrapiCarrierResources {
        edges {
          node {
            PartnersCards {
              alternativeText
              caption
              url
            }
            data {
              secondSection {
                description
                button
              }
            }
          }
        }
      }
    }
  `)

  const { PartnersCards, data } = Data.allStrapiCarrierResources.edges[0].node
  const sectionData = data.secondSection

  const HandleLink = ({ key, card, children }) =>
    card.caption?.includes('cloverly') ? (
      <Link
        key={key}
        to="/cloverly"
        target="_blank"
        className="partners-items-content"
        rel="noopener noreferrer"
      >
        {children}
      </Link>
    ) : (
      <Link
        key={key}
        href={card.caption}
        target="_blank"
        className="partners-items-content"
        rel="noopener noreferrer"
      >
        {children}
      </Link>
    )

  const partnerCards = useMemo(
    () =>
      PartnersCards.map((card, key) =>
        card.caption !== '' ? (
          <HandleLink key={key} card={card}>
            <img
              src={card.url}
              alt={card.alternativeText}
              className="partner-logo"
            />
          </HandleLink>
        ) : (
          <section key={key} className="partners-items-content">
            <img
              src={card.url}
              alt={card.alternativeText}
              className="partner-logo"
            />
          </section>
        )
      ),
    [PartnersCards]
  )

  return (
    <SecondSectionWrapper>
      <div className="partners-description-button">
        <h2 className="title">{sectionData.description}</h2>
        <Link to="/contact">
          <button className="partners-button">{sectionData.button}</button>
        </Link>
      </div>
      <Fade left>
        <div className="partners-items">{partnerCards}</div>
      </Fade>
    </SecondSectionWrapper>
  )
}

export default SecondSection
