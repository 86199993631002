import styled from 'styled-components'

export const BackgroundContainer = styled.div`
  position: relative;
  background-color: #f8fcfd;
  z-index: 0;
  display: flex;
  align-content: center;
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }
`

export const Container = styled.div`
  padding: 6rem 5rem;
  max-width: 120rem;
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-height: 100rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  h2 {
    font-weight: 800;
    font-size: 5rem;
    line-height: 8rem;
    color: #373f49;
    letter-spacing: 0px;
    margin-bottom: 0.6rem;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 3.4rem;
    max-width: 60rem;
    color: #788293;
    margin-bottom: 5rem;
  }

  .capacity-button {
    max-width: 200px;
    width: 80%;
    border: 4px solid #147efb;
    background: #147efb;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 1.8rem;
    color: white;
    z-index: 1;
    font-size: 1.6rem;

    &:hover {
      background: #147efb;
      border: 4px solid #147efb;
      color: white;
    }
  }

  @media only screen and (max-width: 500px) {
    min-height: 90rem;
    padding: 1rem 1rem;
    text-align: center;

    h2 {
      font-size: 3.7rem;
      line-height: 4rem;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    .capacity-button {
      width: 100%;
      font-size: 1.4rem;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    min-height: 120rem;
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 1150px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 501px) and (max-width: 1440px) {
    h2 {
      font-size: 4.5rem;
      line-height: 8rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 3rem;
    }
  }

  @media (min-width: 1440px) and (max-width: 1749px) {
    max-width: 130rem;
  }

  @media (min-width: 1750px) {
    max-width: 135rem;
  }
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5rem;
  z-index: 1;

  & img {
    max-width: 35rem;
    width: 90%;
    margin: 4rem 0;
  }

  @media only screen and (max-width: 500px) {
    min-width: unset;
    width: 100%;
    margin-right: 0;

    & img {
      margin: 4rem auto;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 50rem;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  div {
    width: unset !important;
    height: unset !important;
  }

  video {
    width: 120%;
    right: 5rem;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    video {
      width: 50rem !important;
      position: absolute;
      left: 3.2rem;
      top: 65rem;
      height: unset !important;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1440px) {
    video {
      top: 60rem;
      width: 60rem;
      position: absolute;
      right: 5rem;
    }
  }

  @media only screen and (min-width: 1750px) {
    video {
      width: 110rem;
      position: absolute;
      right: -17rem;
    }
  }

  @media only screen and (max-width: 500px) {
    video {
      max-width: 33rem !important;
      width: 90%;
      position: absolute;
      left: 1.5rem;
      top: 58rem;
      height: unset !important;
    }
  }
`

export const CirclesImages = styled.img`
  position: absolute !important;
  right: 0;
  top: 0;
  width: 134rem;
  height: 73rem;
  z-index: -1;

  

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    left: 1rem;
    top: 50rem;
    width: 50rem;
    height: 58rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 900px) {
    top: 0rem;
    width: 70rem;
    height: 53rem;
  }

  @media only screen and (min-width: 901px) and (max-width: 1150px) {
    top: 0rem;
    width: 90rem;
    height: 53rem;
  }

  @media only screen and (min-width: 1750px) {
    width: 130rem;
    height: 83rem;
  }

  @media only screen and (max-width: 500px) {
    left: 1rem;
    top: 50rem;
    max-width: 33rem;
    width:80%;
    height: auto;
  }
`

export const ParticlesContainer = styled.div`
  z-index: -1;
`
