import React from 'react'
import FirstSectionWrapper, { BackgroundContainer } from './firstSection.style'
import { graphql, useStaticQuery } from 'gatsby'

const FirstSection = () => {
  const Data = useStaticQuery(graphql`
    query FirstSectiondCarrierResourcesQuery {
      strapiCarrierResources {
        data {
          firstSection {
            content
          }
        }
        HeaderSect1 {
          publicURL
        }
      }
    }
  `)
  const { data, HeaderSect1 } = Data.strapiCarrierResources
  const sectionData = data.firstSection
  return (
    <section id="CRHeader">
      <BackgroundContainer backgroundUrl={HeaderSect1.publicURL}>
        <FirstSectionWrapper>
          <h1>{sectionData.content}</h1>
        </FirstSectionWrapper>
      </BackgroundContainer>
    </section>
  )
}

export default FirstSection
