import styled from 'styled-components'

const ConinerTestimonials = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5rem;

  .header {
    margin: 0 auto 50px auto;
    background-color: #ffffff;
    text-align: center;
  }

  .header h2 {
    font-size: 3.75rem;
    font-weight: 700;
    color: #373f49;
  }

  .header p {
    font-size: 30px;
    font-weight: 400;
    color: #373f49;
    :20px ;
  }
`

const ContianerNews = styled.div`
  height: 100%;
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;

  .react-multi-carousel-list.containerNews {
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
  }
`

export { ContianerNews, ConinerTestimonials }
