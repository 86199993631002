import styled from 'styled-components'

const SecondSectionWrapper = styled.div`
  margin: auto;
  max-width: 135rem;
  @media (max-width: 1200px) {
    padding: 3rem;
  }

  display: grid;
  align-content: center;
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }

  .title {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .partners-description-button {
    display: grid;
    justify-items: center;
  }

  h2 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #147efb;
    line-height: 40px;
  }

  @media (min-width: 720px) and (max-width: 1038px) {
    font-size: 25px;
  }

  @media screen and (min-width: 1038px) {
    font-size: 22px;
  }

  .partners-button {
    min-width: 190px;
    height: 45px;
    border-radius: 50px;
    cursor: pointer;
    background-color: #147efb;
    border: 4px solid #147efb;
    color: #ffffff;
    font-size: 1.6rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-bottom: 50px;

    @media (max-width: 720px) {
      margin-bottom: 50px;
    }

    &:hover {
      background: #147efb;
      border: 4px solid #147efb;
    }

    &:active {
      transform: translateY(5px);
    }

    &:focus {
      outline: none;
    }
  }

  .partners-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    margin: auto;
    padding: 0 0 10rem;
    width: 90%;
    transition: all 0.2s ease-in;

    @media (min-width: 400px) {
      width: 100%;
    }
    @media (min-width: 900px) {
      gap: 5rem;
    }
  }

  .partners-items-content {
    box-shadow: 0 3px 7px #ccc;
    padding: 2rem;
    width: 300px;
    aspect-ratio: 4 / 3;
    border-radius: 10px;
    display: grid;
    place-content: center;
    transition: all 0.2s ease-in;

    &:hover {
      transform: scale(1.1);
    }
  }

  .partner-logo {
    width: 100%;
  }
`

export default SecondSectionWrapper
