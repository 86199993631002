import styled from 'styled-components'

export const ContainerHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: 1rem 5%;
 
  & .header-content__logo {
    width: 250px;
  }
`

export const Button = styled.button`
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 1rem 3rem;
  font-family: Poppins, sans-serif;
`
export const HeaderAnimatiomWrapper = styled.div`
  position: relative;
  background-color: #080808;
  width: 100%;
  height: 120px;
  @media (max-width: 1090px) {
    height:15rem;
  }
  @media (max-width: 900px) {
    height:30rem;
  }
  @media (max-width: 570px) {
    height:35rem;
  }
 
  .header-content {
    position: absolute;
    height: 100%;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;
    padding: 2rem 2rem 2rem 8rem;
  }

  .line {
    border-left: 3px solid white;
    height: 100%;
  }

  .header-content__text {
    letter-spacing: 0px;
    text-align: center;
  }
  .header-content__text h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    color: #ffffff;
    margin: 0;
    padding: 0;
    letter-spacing: 0;
  }
  .header-content__text p {
    color: #ffffff;
    font-size: 16px;
    margin: 0;
  }
  .header-button {
    justify-self: end;
  }
  .header-button__botton {
    width: 140px;
    height: 40px;
    border: none;
    background-color: #ffffff;
    color: #080808;
    font-size: 16px;
    font-weight: 500;
  }

  @media (min-width: 1024px) {
    .header-content {
      justify-content: space-between;
    }
  }
  @media (max-width: 1024px) {
    .line {
      display: none;
    }
    .header-content {
    }
    .header-content__text h2 {
      margin-right:4rem;
      margin-left:4rem;
    }
  .header-content {
    
      justify-content: center;
   padding-left: 0;
   padding-right: 0;
   margin-bottom:10rem;
    }
    .header-content__text p {
      margin-right: 3rem;
      margin-left:3rem;
    }

  }
  @media (min-width: 1340px) {
    .header-content__text h2 {
      font-size: 34px;
    }
    .header-content__text p {
      font-size: 18px;
    }

  }
  @media (max-width: 570px){
    .header-content__text h2 {
      margin-top: 1rem;
      margin-bottom:1rem;
    }
    .header-content__text p {

      margin-top:1rem;
      margin-bottom:1rem;
    }
  }  

  }
  }
`
