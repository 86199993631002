//  libraries
import React from 'react'

//  containers
import FirstSection from '../containers/CarrierResoursesPage/firstSection/firstSection'
import SecondSection from '../containers/CarrierResoursesPage/secondSection/secondSection'
import Testimonials from '../containers/CarrierResoursesPage/Testimonials'
//  context

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'
import Header from '../containers/CarrierResoursesPage/header/header'

const EdgeSolutions = () => {
  return (
    <Layout>
      <SEO title="Partner Resources - Edge Logistics" />
      {/* <Header /> */} {/** CAPACITY */}
      <FirstSection />
      <SecondSection />
      {/* <Testimonials /> */} {/** CAPACITY */}
    </Layout>
  )
}

export default EdgeSolutions
